import { addQueryArgs } from '@wordpress/url';
import { getOrderParams } from '../mixins/property-list';
import { getParamsOfUrl, getUrl } from '../mixins/search';
import { getQuery } from '../shortcode/advanced-search/advanced-search';

const archivePropertyNode = document.querySelector( '.realpress-archive-property' );
const sortNode = archivePropertyNode.querySelector( '.realpress-sort-by select' );
const containerNode = archivePropertyNode.querySelector( '.realpress-property-container' );
const searchNode = document.querySelector( '.realpress-advanced-search' );
//Loadmore
const loadMoreWrapperNode = archivePropertyNode.querySelector( '.realpress-load-more' );
let loadmoreBtnNode, loadingNode;

//infinite scroll
const infiniteScrollNode = archivePropertyNode.querySelector( '.realpress-infinite-scroll' );
let infiniteLoadingNode;
//-----------
const restNamespace = REALPRESS_GLOBAL_OBJECT.rest_namespace || '';

let paginationType;
let defaultQuery = { orderby: 'date', order: 'desc' };
let query = {};
let isFetching = false;
let nextPage = null;
let reseting = false;

const init = () => {
	const { taxonomy = '' } = REALPRESS_PROPERTY_ARCHIVE_OBJECT;
	const termId = REALPRESS_PROPERTY_ARCHIVE_OBJECT.term_id || '';
	if ( !! termId && taxonomy ) {
		defaultQuery = { ...defaultQuery, term_id: termId, taxonomy };
	}

	if ( typeof REALPRESS_GLOBAL_OBJECT.pll_current_lang !== 'undefined' ) {
		defaultQuery = { ...defaultQuery, pll_current_lang: REALPRESS_GLOBAL_OBJECT.pll_current_lang };
	}

	if ( typeof REALPRESS_GLOBAL_OBJECT.wpml_current_lang !== 'undefined' ) {
		defaultQuery = { ...defaultQuery, wpml_current_lang: REALPRESS_GLOBAL_OBJECT.wpml_current_lang };
	}

	const queryParams = getParamsOfUrl();

	if ( Object.keys( queryParams ).length ) {
		query = { ...query, ...queryParams };
	}

	//Loadmore
	if ( loadMoreWrapperNode ) {
		loadmoreBtnNode = loadMoreWrapperNode.querySelector( '.realpress-load-more-btn' );
		loadingNode = loadMoreWrapperNode.querySelector( '.realpress-loading-display' );
		paginationType = 'loadmore';
	}

	//Infinite Scroll
	if ( infiniteScrollNode ) {
		infiniteLoadingNode = infiniteScrollNode.querySelector( '.realpress-loading-display' );
		paginationType = 'infinite_scroll';
	}

	if ( ! paginationType ) {
		paginationType = 'standard';
	}

	defaultQuery = { ...defaultQuery, pagination_type: paginationType };
};

const realpressListProperty = () => {
	init();
	sort();
	if ( searchNode ) {
		reset();
		search();
	}
	getProperties( query );
	pagination();
};

const getProperties = ( queryParam = {}, isLoadmore = false ) => {
	if ( isLoadmore === false ) {
		containerNode.style.opacity = 0.4;
	}

	let param = { ...defaultQuery, ...queryParam };

	if ( containerNode.getAttribute( 'data-loop-item-id' ) ) {
		param = { ...param, 'loop-item-id': containerNode.getAttribute( 'data-loop-item-id' ) };
	}

	wp.apiFetch( {
		path: addQueryArgs( '/' + restNamespace + '/properties', param ),
		method: 'GET',
	} ).then( ( res ) => {
		if ( res.data.content !== undefined ) {
			if ( isLoadmore ) {
				containerNode.insertAdjacentHTML( 'beforeend', res.data.content );
			} else {
				containerNode.innerHTML = res.data.content;
			}
		} else {
			if ( loadMoreWrapperNode ) {
				loadMoreWrapperNode.style.display = 'none';
			}
			if ( res.msg && isLoadmore === false ) {
				containerNode.innerHTML = `<p>${ res.msg }</p>`;
			}
		}
		renderPagination( res );
	} ).catch( ( err ) => {
		console.log( err );
	} ).finally( () => {
		if ( isLoadmore === false ) {
			const urlPush = getUrl( query );
			window.history.pushState( '', '', urlPush );
		}
		containerNode.style.opacity = 1;
	} );
};

const sort = () => {
	if ( ! sortNode ) {
		return;
	}

	sortNode.addEventListener( 'change', function( event ) {
		if ( reseting ) {
			return;
		}

		const selected = event.target.value;
		const sortParams = getOrderParams( selected );
		query = { ...query, ...sortParams };
		//Loadmore and infinite scroll
		if ( paginationType !== 'standard' && Object.hasOwn( query, 'page' ) ) {
			delete query.page;
		}
		getProperties( query );
	} );
};

const reset = () => {
	const resetButtonNode = searchNode.querySelector( 'button.realpress-reset' );
	if ( resetButtonNode ) {
		resetButtonNode.addEventListener( 'click', function() {
			reseting = true;
			sortNode.tomselect.setValue( 'default' );

			query = {};

			//For demo
			const queryParams = getParamsOfUrl();
			if ( Object.hasOwn( queryParams, 'view' ) && ( queryParams.view === 'loadmore' || queryParams.view === 'infinite_scroll' ) ) {
				query.view = queryParams.view;

				containerNode.innerHTML = '';
				if ( loadingNode ) {
					loadingNode.style.display = 'block';
					loadmoreBtnNode.innerHTML = '';
				}

				if ( infiniteLoadingNode ) {
					infiniteLoadingNode.style.display = 'block';
				}
			}

			//end
			getProperties( query );
			reseting = false;
		} );
	}
};

const search = () => {
	document.addEventListener( 'click', function( event ) {
		if ( event.target.classList.contains( 'realpress-search' ) || event.target.classList.contains( 'realpress-view-all' ) ) {
			query = getQuery( query );

			if ( Object.hasOwn( query, 'page' ) ) {
				delete query.page;
			}

			getProperties( query );
		}
	} );
};

const renderPagination = ( res ) => {
	if ( paginationType === 'standard' ) {
		if ( res.data.pagination !== undefined ) {
			archivePropertyNode.querySelector( '.realpress-pagination' ).innerHTML = res.data.pagination;
		} else {
			archivePropertyNode.querySelector( '.realpress-pagination' ).innerHTML = '';
		}

		if ( res.data.from_to !== undefined ) {
			archivePropertyNode.querySelector( '.realpress-from-to' ).innerHTML = res.data.from_to;
		} else {
			archivePropertyNode.querySelector( '.realpress-from-to' ).innerHTML = '';
		}
	}

	if ( paginationType === 'loadmore' ) {
		if ( res.data.pagination === undefined ) {
			nextPage = null;
			return;
		}

		loadmoreBtnNode.innerHTML = res.data.pagination;
		loadingNode.style.display = 'none';
		if ( res.data.next_page ) {
			nextPage = res.data.next_page;
		} else {
			nextPage = null;
		}
	}

	if ( paginationType === 'infinite_scroll' ) {
		infiniteLoadingNode.style.display = 'none';
		isFetching = false;
		if ( res.data.next_page ) {
			nextPage = res.data.next_page;
		} else {
			nextPage = null;
		}
	}
};

const pagination = () => {
	document.addEventListener( 'click', function( event ) {
		const target = event.target;
		const item = target.closest( 'a' );
		if ( paginationType === 'standard' ) {
			if ( ! item ) {
				return;
			}

			if ( ! item.getAttribute( 'data-page' ) ) {
				return;
			}

			const paginationNode = target.closest( '.realpress-pagination' );
			if ( ! paginationNode ) {
				return;
			}
			event.preventDefault();
			const page = item.getAttribute( 'data-page' );
			query = { ...query, page };
			getProperties( query );
			archivePropertyNode.scrollIntoView( { behavior: 'smooth' } );
		}

		if ( paginationType === 'loadmore' ) {
			if ( target.parentNode !== loadmoreBtnNode ) {
				return;
			}

			event.preventDefault();
			target.remove();
			loadingNode.style.display = 'block';
			query = { ...query, page: nextPage };
			getProperties( query, true );
		}
	} );

	if ( paginationType === 'infinite_scroll' ) {
		window.addEventListener( 'scroll', ( event ) => {
			getScrollData();
		} );
	}
};

const getScrollData = () => {
	const distance = infiniteScrollNode.getBoundingClientRect().top - window.innerHeight;

	if ( isFetching === false && distance < 0 && nextPage ) {
		isFetching = true;
		infiniteLoadingNode.style.display = 'block';
		query = { ...query, page: nextPage };
		getProperties( query, true );
	}
};

export default realpressListProperty;
