import { addQueryArgs } from '@wordpress/url';

const getParamOfUrl = ( param ) => {
	const urlParams = new URLSearchParams( location.search );

	return urlParams.get( param );
};

const getParamsOfUrl = () => {
	const urlParams = new URLSearchParams( location.search );

	const queryParams = {};
	for ( const [ key, value ] of urlParams ) {
		queryParams[ key ] = value;
	}

	return queryParams;
};

const getUrl = ( args ) => {
	return addQueryArgs( document.location.origin + document.location.pathname, args );
};

export { getParamOfUrl, getParamsOfUrl, getUrl };

