let archivePropertyContentNode, switchViewNodes, container;
const realpressSwitchLayout = () => {
	archivePropertyContentNode = document.querySelector( '.realpress-archive-property-content' );

	if ( ! archivePropertyContentNode ) {
		return;
	}

	switchViewNodes = archivePropertyContentNode.querySelectorAll( '.realpress-item-switch-view li' );
	if ( ! switchViewNodes.length ) {
		return;
	}
	container = archivePropertyContentNode.querySelector( '.realpress-property-container' );

	let col = JSON.parse( localStorage.getItem( 'realpress_archive_property_layout_active' ) );
	let activeNode = archivePropertyContentNode.querySelector( '.realpress-item-switch-view li[data-grid-col="' + col + '"]' );

	if ( col == null || ! activeNode ) {
		const pageLayout = REALPRESS_GLOBAL_OBJECT.property_page_layout || 'list';
		if ( pageLayout === 'list' ) {
			activeNode = switchViewNodes[ 0 ];
		} else {
			activeNode = switchViewNodes[ switchViewNodes.length - 1 ];
		}
		col = activeNode.getAttribute( 'data-grid-col' );
		activeNode.classList.add( 'active' );
		localStorage.setItem( 'realpress_archive_property_layout_active', JSON.stringify( col ) );
	}

	activeNode.classList.add( 'active' );
	container.setAttribute( 'data-grid-col', col );

	switchViewMode();
};

const switchViewMode = () => {
	for ( let i = 0; i < switchViewNodes.length; i++ ) {
		switchViewNodes[ i ].addEventListener( 'click', function() {
			const activeViewNode = archivePropertyContentNode.querySelector( '.realpress-item-switch-view li.active' );
			const activeView = activeViewNode.getAttribute( 'class' );
			const view = this.getAttribute( 'class' );
			if ( view !== activeView ) {
				activeViewNode.classList.remove( 'active' );
				this.classList.add( 'active' );
				const col = this.getAttribute( 'data-grid-col' );
				container.setAttribute( 'data-grid-col', col );
				localStorage.setItem( 'realpress_archive_property_layout_active', JSON.stringify( col ) );
			}
		} );
	}
};

export default realpressSwitchLayout;
